var allowCookies, cookiesAllowed, getCookie, ready, setCookie;

cookiesAllowed = null;

setCookie = function(name, value, days) {
    var date, expires;
    if (days) {
        date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    return document.cookie = name + "=" + value + expires + "; path=/";
};

getCookie = function(name) {
    var c, ca, i, nameEQ;
    nameEQ = name + "=";
    ca = document.cookie.split(";");
    i = 0;
    while (i < ca.length) {
        c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
        }
        i++;
    }
    return null;
};

allowCookies = function() {
    setCookie('otb_cookies_note_readed', 'yes', 30);
    cookiesAllowed = 'yes';
    $('#cookies-bar').fadeOut();
};

ready = function() {
    cookiesAllowed = getCookie('otb_cookies_note_readed');
    return $('a#read-cookie-hint').on('click', function(e) {
        if (cookiesAllowed !== 'yes') {
            return allowCookies();
        }
    });
};

$(document).ready(ready);

$(document).on('page:load', ready);