(function($) {
  /**
   * renders the image as figure and the alt tag als figcaption
   */
  $.fn.renderImage = function() {
    var img = $(this).wrap($('<figure class="image"></figure>'));

    img.each(function() {
      var el = $(this);
      var cap = $('<figcaption>', {text: el.attr('alt')});

      el.click(function() {
        el.parent().toggleClass('full');
        cap.width(el.width());
      });

      el.on('load', function() {
        cap.width(el.width());
        el.after(cap);
      });

      window.addEventListener('resize', function() {
        if (el.parent().hasClass('full')) {
          cap.width(el.width());
        }
      });
    });
  };

  /**
   * renders the footnotes after the author-info
   *
   */
  $.fn.postPreview = function() {
    var notes = $(this).find('div.footnotes').detach();

    $(this).find('div.author-info').after(notes);
  };
})(jQuery);

