import '@fortawesome/fontawesome-free/css/all.css';
import 'flexslider'

require('../stylesheets/public.scss');
require('../src/cookieBar');
require('../src/renderFilter');
require('../src/renderShow');
require.context('../images', true);

(function($) {
  // Let's go.
  $(document).ready(function() {
    $('div.markdown img').renderImage();
    $('div.post').postPreview();
    $('div.show').postPreview();
    $('div.gallery').flexslider({
      animation: 'slide'
    });
    $('div.filter').clearFilter();
    $('div#filter-mask').filterBox();
  });
})(jQuery);
