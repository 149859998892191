(function($) {
  $.fn.filterBox = function() {
    return this.each(function () {
      var el = $(this);
      var cat = el.find('.category select');
      var issue = el.find('.issue');

      cat.change(function() {
        if (cat.val() == 'posts') {
          issue.show().children().prop('disabled', false);
        } else {
          issue.hide().children().prop('disabled', true);
        }
      });

      cat.trigger('change');
    });
  };

  $.fn.clearFilter = function() {
    return this.each(function () {
      var el = $(this);
      var btn = $('<a>', {text: 'x', class: 'reset'});

      btn.click(function() {
        el.children('input[type="text"],select').val('').trigger('change');
      });

      el.append(btn);
    });
  };
})(jQuery);
